import req from '../utils/request.js'

const homeApi = {
    
    // 查询用户列表
    getHomeData(){
        return req({
            url: '/home/',
            method: 'GET',
        })
    }
    }

export default homeApi