import axios from 'axios'
import { ElMessage  } from 'element-plus'
const req = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 50000
})
// 请求前
// 添加请求拦截器
req.interceptors.request.use(function(config) {
  // 请求前 成功 做什么
//   const get_sid = sessionStorage.getItem('sid')
//   if (get_sid){config.headers.Authorization = get_sid}
  return config
}, function(error) {
  // 请求前 失败 做什么
  return Promise.reject(error)
})

// 请求响应
// 添加响应拦截器
req.interceptors.response.use(function(response) {
  // 请求成功做什么
  // 对响应数据做点什么
  // sessionStorage.setItem('user', `${response.data.user}`)
  // console.log(get_info.getUser().is_type);
  return response.data
}, 
function(error) {
  // 对响应错误 做点什么
  const errors = error.response.data
  if (error.response.status < 500) {
    ElMessage({
      message: errors,
      type: 'error',
      duration: 3 * 1000
      })  
  }
  else{
    ElMessage({
      message: "服务维护中。。。",
      type: 'error',
      duration: 3 * 1000
    })
  }
} 
)

export default req
